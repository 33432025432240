export const ENV: string = import.meta.env.MODE!;

export const API_URL: string = import.meta.env.VITE_API_URL!;

export const MOBILE_APP_GOOGLE_PLAY_STORE_URL: string = import.meta.env
  .VITE_MOBILE_APP_GOOGLE_PLAY_STORE_URL!;

export const MOBILE_APP_IOS_APP_STORE_URL: string = import.meta.env
  .VITE_MOBILE_APP_IOS_APP_STORE_URL!;

export const ANDROID_URL_SCHEME: string = import.meta.env.VITE_ANDROID_URL_SCHEME!;

export const IOS_URL_SCHEME: string = import.meta.env.VITE_IOS_URL_SCHEME!;

export const DEFAULT_COUNTRY_CODE: string = import.meta.env?.VITE_DEFAULT_COUNTRY_CODE || 'NG';

const ENTERPRISE_ACCESS_KEYS: string = import.meta.env.VITE_ENTERPRISE_ACCESS_KEYS!;

export const ENTERPRISE_ACCESS = ENTERPRISE_ACCESS_KEYS ? ENTERPRISE_ACCESS_KEYS.split(',') : [];

const BVN_REQUIRED_COUNTRY_IDS: string = import.meta.env.VITE_BVN_REQUIRED_COUNTRY_IDS!;

export const BVN_REQUIRED_COUNTRIES = BVN_REQUIRED_COUNTRY_IDS
  ? BVN_REQUIRED_COUNTRY_IDS.split(',').filter(Boolean)
  : [];

export const SENTRY_ENV = import.meta.env?.VITE_SENTRY_ENV || ENV;

export const SENTRY_KEY = import.meta.env.VITE_SENTRY_PUBLIC_KEY;

export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env?.VITE_SENTRY_TRACES_SAMPLE_RATE
  ? Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
  : 0;

export const SENTRY_RELAYS_SESSION_SAMPLE_RATE = import.meta.env
  ?.VITE_SENTRY_RELAYS_SESSION_SAMPLE_RATE
  ? Number(import.meta.env.VITE_SENTRY_RELAYS_SESSION_SAMPLE_RATE)
  : 0;

export const SENTRY_ON_ERROR_SAMPLE_RATE = import.meta.env?.VITE_SENTRY_ON_ERROR_SAMPLE_RATE
  ? Number(import.meta.env.VITE_SENTRY_ON_ERROR_SAMPLE_RATE)
  : 0;

export const HOMEPAGE_URL = import.meta.env.VITE_BRASS_HOMEPAGE_URL!;
export const FACEBOOK_APP_ID = import.meta.env.VITE_FACEBOOK_APP_ID!;
export const ONBOARDING_URL: string = import.meta.env.VITE_ONBOARDING_URL!;
export const MAIN_URL: string = import.meta.env.VITE_MAIN_URL!;

export const API_KEY: string = import.meta.env.VITE_API_KEY!;

export const BEAMER_URL = import.meta.env.VITE_BEAMER_URL!;
export const BEAMER_PRODUCT_ID = import.meta.env.VITE_BEAMER_PRODUCT_ID!;
export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIES_DOMAIN!;
export const CDN_URL = import.meta.env.VITE_CDN_URL!;

// Zendesk
export const ZENDESK_URL: string = import.meta.env.VITE_ZENDESK_URL!;
export const ZENDESK_URL_KEY: string = import.meta.env.VITE_ZENDESK_URL_KEY!;
export const ZENDESK_ID: string = import.meta.env.VITE_ZENDESK_ID!;

//Redux Persist
export const BRASS_APP_PERSIST_KEY = import.meta.env?.VITE_BRASS_APP_PERSIST_KEY || 'DEMO_KEY';

// ANALYTICS
export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY!;

export const APPS_FLYER_WEB_SDK_URL = import.meta.env.VITE_APPS_FLYER_WEB_SDK_URL!;

export const APPS_FLYER_WEB_SDK_KEY = import.meta.env.VITE_APPS_FLYER_WEB_SDK_KEY!;

// FAQ CMS
export const BRASS_CMS_URL: string = import.meta.env.VITE_BRASS_CMS_URL!;

export const SUPPORT_EMAIL_NG: string = import.meta.env.VITE_BRASS_SUPPORT_EMAIL_NG!;
export const SUPPORT_EMAIL_KE: string = import.meta.env.VITE_BRASS_SUPPORT_EMAIL_KE!;

export const SUPPORT_PHONE_NUMBER_NG: string = import.meta.env.VITE_BRASS_SUPPORT_PHONE_NUMBER_NG!;
export const SUPPORT_PHONE_NUMBER_KE: string = import.meta.env.VITE_BRASS_SUPPORT_PHONE_NUMBER_KE!;
